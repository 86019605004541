











import { Component, Prop, Vue, Provide } from "vue-property-decorator";
import { accountRegister, accountRegisterResend } from '../../../apis/account.api';
import { SyncWithRouterQuerySimple } from '../../../utils/decorators/SyncWithRoute';

@Component
export default class RegisterConfirmSuccess extends Vue {
  
  

}
